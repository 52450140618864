@import 'src/shared-styles/global-variables';

$greyShadow: 0 3px 10px rgba(0, 0, 0, 0.1);
.static-template {
  font-family: $fontFamilyRegular;

  > div,
  > section {
    &:last-child {
      margin-bottom: 40px;
    }

    &.flat-bottom {
      margin-bottom: 0 !important;
    }
  }

  > section:not(:first-child):not(:last-child) {
    margin-top: 40px;
    margin-bottom: 40px;

    @media screen and (max-width: $break-medium) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .static-template-container {
    &:last-child {
      margin-bottom: 40px;
    }
  }

  #lead-gen-form {
    &.lead-gen-form-success {
      .inner {
        .row {
          h5 {
            background-size: 45px;
            background-position: 0 50%;
            @media screen and (max-width: $break-medium) {
              background-position: 15px 50%;
            }
          }
        }
      }
    }

    .inner {
      margin-top: 0 !important;
      border-radius: 0 5px 5px 0;
      @media screen and (max-width: $break-medium) {
        border-radius: 0 0 3px 3px;
      }
    }

    label {
      width: 100% !important;
      margin-left: 0 !important;
      margin-bottom: 10px !important;
    }

    .col-lg-4 {
      &.col-md-4 {
        &.col-xs-12 {
          display: none !important;
        }
      }
    }

    .col-lg-8 {
      &.col-md-8 {
        &.col-xs-12 {
          width: 100% !important;

          #lead-gen-form {
            label:first-of-type {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .static-template-container {
    margin-top: 50px;

    ul {
      li {
        font-size: 1rem;
      }
    }

    img {
      width: 100%;
      border-radius: 3px;
    }

    &.discovery-tours {
      .red-fade {
        text-align: left !important;

        &:after {
          background: linear-gradient(to right, #bc2224 0%, rgba(188, 34, 36, 0.05) 100%);
        }
      }

      .generic-expat-styled {
        width: 90%;

        .form-group.phone-group {
          select {
            width: 25%;
            padding: 5px;
            height: 45px;
          }

          input {
            width: 75%;
            height: 45px;
          }
        }

        textarea {
          height: 150px;
        }

        .generic-expat-form-cta {
          display: block;
          margin: 5px auto;
          width: 50%;
          line-height: 40px;
        }

        .input-group.long-label {
          strong {
            display: none;
          }

          label {
            display: inline-block;
          }
        }

        label {
          margin-bottom: 5px;
        }

        select,
        input,
        textarea {
          font-weight: 600 !important;
          text-indent: 0;

          &:focus {
            outline: none;
          }

          &::-webkit-input-placeholder {
            color: #bfbfbf;
            font-weight: 600;
          }
        }

        .disabled-option {
          color: #bfbfbf;
          font-weight: 600;
        }

        select {
          transition: all 0.3s ease;
          cursor: pointer;
          background-color: #f2f2f2;
        }

        .req-tag {
          width: 100%;
        }

        @media screen and (max-width: $break-medium) {
          width: 100%;
          .form-group.phone-group {
            select,
            input {
              width: 100%;
              margin-bottom: 5px;
            }

            input {
              margin-bottom: 25px;
            }
          }
          .input-group.long-label {
            strong {
              display: block;
            }

            label {
              display: none;
            }
          }
          .generic-expat-form-cta {
            width: 90%;
          }
        }
      }
    }
  }

  button,
  a {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: 0;
      opacity: 1;
    }
  }

  .no-pad-right-large {
    @media screen and (min-width: $break-medium) {
      padding-right: 0;
    }
  }

  .no-pad-left-large {
    @media screen and (min-width: $break-medium) {
      padding-left: 0;
    }
  }

  .static-template-card {
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    box-shadow: $greyShadow;

    h1,
    h2 {
      font-size: 1.75rem;
      font-weight: bold;
      margin: 0 0 20px;
      color: $red;

      &::after {
        content: '';
        display: block;
        margin-top: 10px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
          to right,
          rgba(188, 34, 36, 1) 0%,
          rgba(254, 253, 253, 0) 99%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    p {
      font-size: 1rem;
      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .full-height-card {
    .static-template-card {
      height: 100%;
      border-radius: 3px 0 0 3px;
    }
  }

  .static-template-single-image {
    &.cta {
      .image-wrapper {
        position: relative;

        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
          width: 100%;
          text-align: center;

          a {
            cursor: pointer;
            transition: all 0.2s ease;
            display: inline-block;
            min-width: 175px;
            margin: 0 auto;
            font-weight: bolder;
            font-size: 1.25rem;
            text-transform: uppercase;
            letter-spacing: 0.3px;
            padding: 10px 20px;
            border-radius: 2px;
            background: #5ac7da;
            color: #fff;

            &:hover {
              background: #087f94;
              padding: 15px 25px;
              opacity: 1;
            }
          }
        }
      }
    }

    &.form {
      .static-template-card.modern-form {
        h3 {
          text-align: center;
          font-family: amaranth, Arial, Helvetica, sans-serif;
          color: #010101;
          font-size: 33px;
          font-weight: 900;
          margin-bottom: 15px;
          line-height: 1.5rem;

          p {
            font-weight: 600;
            font-size: 1.125rem;
          }
        }

        form {
          max-width: 85%;
          margin: 20px auto;

          .form-group {
            margin-bottom: 20px;
          }

          label {
            display: block;
            margin-bottom: 5px;
            color: #010101;
            font-size: 1.25rem;

            span.req-tag {
              float: right;
            }
          }

          span.req-tag {
            font-size: 0.938rem;
            color: #ba2426;
          }

          .form-control {
            background: #e8e8e8;
            border: 0;
            border-radius: 0;
            padding: 10px 15px;
          }

          .form-upload-group {
            margin-top: 25px;
            text-align: center;

            .file-uploader {
              display: none;
            }
          }

          .form-check {
            .form-check-input {
              cursor: pointer;
              transition: all 0.5s ease;
              border: 2px solid #707070;
              height: 15px;
              width: 15px;

              &:checked {
                border: 2px solid transparent;
              }
            }

            label,
            label p {
              font-size: 0.75rem;
              line-height: 1rem;
              padding-top: 2px;
            }
          }

          .form-footer {
            margin-top: 20px;
            margin-bottom: 0;
            text-align: center;

            .btn-wrapper.disabled {
              cursor: not-allowed !important;
              display: inline-block;
            }

            a {
              color: #010101;
              text-decoration: underline;
              font-size: 0.75rem;
            }
          }

          .invalid-feedback {
            display: block;
            padding-left: 15px;
          }

          .btn {
            cursor: pointer;
            transition: all 0.2s ease;
            display: inline-block;
            min-width: 175px;
            margin: 0 auto;
            font-weight: bolder;
            font-size: 1.25rem;
            text-transform: uppercase;
            letter-spacing: 0.3px;
            padding: 5px 20px;
            border-radius: 2px;
            color: #fff;

            &.btn-upload {
              background: #5ac7da;

              &:hover {
                background: #087f94;
              }
            }

            &.btn-success {
              background: #00aa20;

              &:hover {
                background: #005810;
              }
            }
          }

          .photoThumbnail {
            transition: all 0.3s ease;
            margin: 15px auto;
            width: 45%;
            display: block;
            border-radius: 10px;
            box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.1);

            &:hover {
              border-radius: 5px;
              box-shadow: none;
            }
          }
        }
      }

      .static-template-card {
        .req-tag {
          color: #bc2224;
          font-size: small;

          &.m-left {
            margin-left: 15px !important;
          }

          &.m-b-0 {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .media-button {
      p {
        margin: 10px 0 0;
        text-align: center;
      }

      a {
        background: #fcef51;
        border-radius: 3px;
        z-index: 1;
        color: #000;
        font-weight: 700;
        font-family: $fontFamilyRegular;
        box-shadow: 0 0 4px 0 #fcef51;
        text-decoration: none;
        opacity: 1;
        padding: 15px 20px;
        border: 1px solid #e9db29;
        font-size: 1rem;
        margin: 0 auto;
        display: inline-block;
        line-height: 1rem;
        height: auto;
        text-align: center;
        transition: all 0.3s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .static-template-image-gallery {
    .grid-container {
      display: flex;
    }

    .three-col-swiper-container {
      display: none;
    }

    @media (max-width: $break-medium) {
      .grid-container {
        display: none;
      }
      .three-col-swiper-container {
        display: flex;
      }
    }

    img {
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    .three-col-swiper-container {
      .swiper {
        height: unset;

        img {
          margin-bottom: 0;
        }
      }
    }
  }

  .static-template-card-gallery {
    .col-lg-4 {
      img {
        height: 190px;
      }
    }

    .col-lg-6 {
      img {
        height: 230px;
      }
    }

    .static-template-card-sml {
      background-color: #f7f7f7;
      border-radius: 3px;
      height: 100%;

      img {
        border-radius: 3px 3px 0 0;
      }

      .static-template-card-sml-text {
        padding: 20px;
        background: #f7f7f7;
        color: #707070;

        h3 {
          font-family: $fontFamilyRegular;
          font-size: 1.313rem;
          font-weight: bold;
          color: $red;
          margin-top: 0;
          margin-bottom: 10px;

          &::after {
            display: none;
          }
        }

        p {
          font-size: 0.875rem;

          span {
            font-size: 0.875rem;
          }
        }
      }
    }

    @media screen and (max-width: $break-medium) {
      .col-lg-4,
      .col-lg-6 {
        img {
          height: auto;
        }
      }

      .static-template-card-sml {
        .static-template-card-sml-text {
          h3 {
            font-size: 1.125rem;
          }
        }
      }
    }

    &.static-icon-card {
      text-align: center;
      background-color: transparent;

      .static-template-card-sml,
      .static-template-card-sml-text {
        background: #fff !important;
      }

      img {
        height: 55px !important;
        margin: 25px auto 0;
      }

      h1,
      h2,
      h3 {
        margin: 0 0 30px;
      }

      h2:empty {
        display: none;
      }
    }

    &.static-review-card {
      background-color: transparent;

      .static-template-card-sml {
        background-color: transparent;
        text-align: center;
        margin-top: 80px;

        .quote {
          position: relative;

          .quote-mark1,
          .quote-mark2 {
            position: absolute;
            height: 30px;
            width: 30px;
          }

          .quote-mark1 {
            top: 0;
            left: -12px;
          }

          .quote-mark2 {
            bottom: 0;
            right: -12px;
            transform: scaleY(-1) scaleX(-1);
          }

          p {
            font-size: 1rem !important;
            font-weight: 500;
            margin: 0 auto;
            width: 80%;
          }
        }
      }

      img.review-avatar {
        border-radius: 100% !important;
        border: 5px solid #fff;
        height: 175px !important;
        width: 175px;
        margin-top: -65px;
      }

      .static-template-card-sml-text {
        background: #fff !important;
        margin-top: -75px;
        padding-top: 80px !important;
        @media screen and (min-width: $break-medium) {
          min-height: 305px;
        }

        h3 {
          font-size: 1.5rem;
          color: #3a3a3a !important;
          font-weight: 500 !important;
        }

        .quote-copy,
        p {
          font-size: 1rem !important;
          font-weight: 500;
          margin: 0 auto;
          width: 80%;
        }
      }
    }
  }

  .static-template-swiper-btn {
    width: 50px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0;
    border-radius: 3px;
    position: absolute;
    top: 0;
    z-index: 1;

    &.static-template-swiper-btn-prev {
      left: 0;
    }

    &.static-template-swiper-btn-next {
      right: 0;
    }

    img {
      width: 18px;
      height: 30px;
    }
  }

  .static-template-swiper-external-btns-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .static-template-swiper-external-btn {
    background-color: transparent;
    border: 0;
    padding: 10px;

    img {
      width: 9px;
      height: 15px;

      &:nth-of-type(2) {
        display: none;
      }
    }

    span {
      display: inline-block;
      font-size: 0.938rem;
      line-height: 1rem;
      border-bottom: 1px solid;
      color: $red;
    }

    &:disabled {
      cursor: not-allowed;

      img {
        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          display: inline-block;
        }
      }

      span {
        color: #bfbfbf;
      }
    }

    &.static-template-swiper-external-btn-prev {
      span {
        margin-left: 6px;
      }
    }

    &.static-template-swiper-external-btn-next {
      span {
        margin-right: 6px;
      }
    }
  }

  .static-template-swiper-external-dot-btn {
    padding: 12px 10px 10px;

    span {
      display: block;
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 1px solid $red;
      border-radius: 50%;
    }

    &.static-template-active-slide {
      span {
        background-color: $red;
      }
    }
  }

  @media screen and (max-width: $break-medium) {
    &:not(.win-a-free-tour-travel-competition):not(.win-a-free-tour-travel-competition-demo) {
      .static-template-container {
        margin-top: 30px;
        max-width: 600px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }

    .static-template-card {
      padding: 20px;
      font-size: 1rem;

      h2 {
        font-size: 1.313rem;
      }
    }

    .static-template-mobile-padding {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .static-template-mobile-padding {
    &.travel-safety-protocols {
      .mobile-only-true {
        @media screen and (min-width: $break-medium) {
          display: none;
        }
      }

      .mobile-only-false {
        @media screen and (max-width: $break-medium) {
          display: none;
        }
      }
    }
  }

  .static-template-video-gallery {
    ul {
      margin-top: 30px;
      padding-left: 30px;
      list-style: none;
      font-size: 1rem;

      li {
        position: relative;
        margin-left: 15px;
        margin-top: 5px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          bottom: 0;
          left: -15px;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background-color: $red;
          border-radius: 50%;
        }
      }
    }

    .video-container {
      width: 100%;
      max-width: 680px;
      margin: 30px auto 0;

      p {
        margin-top: 40px;
        text-align: center;
        color: #3a3a3a;
      }
    }

    .video-gallery-img-preview {
      position: relative;
      cursor: pointer;

      .static-template-video-play-icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }

      img {
        border-radius: 0;
      }
    }

    .static-video-gallery-selections {
      margin-top: 15px;
    }

    .static-video-gallery-swiper {
      position: relative;

      .static-video-gallery-swiper-inner {
        width: 90%;
        margin: 0 auto;

        img {
          max-height: 185px !important;
          border-radius: 0;
          cursor: pointer;
        }
      }

      .swiper-static-videos-btn-prev,
      .swiper-static-videos-btn-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        text-decoration: underline;
        color: $red;
      }

      .swiper-static-videos-btn-prev {
        left: 0;
      }

      .swiper-static-videos-btn-next {
        right: 0;
      }
    }

    .static-template-load-more {
      display: none;
    }

    @media screen and (max-width: $break-medium) {
      ul {
        padding-left: 20px;
      }

      .video-container {
        p {
          margin-top: 20px;
        }
      }

      .static-template-video-gallery-swiper {
        max-width: 100%;
        margin-top: 0;

        .swiper-wrapper {
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .swiper-slide {
          width: calc(50% - 10px);
          margin-top: 20px;

          &:nth-child(n + 5) {
            display: none;
          }

          .static-template-video-img-container {
            img {
              height: auto;
              min-height: 96px;

              &.static-template-video-play-icon {
                width: 6vw;
                min-width: 20px;
                max-width: 50px;
              }
            }
          }
        }

        .static-template-swiper-btn {
          display: none;
        }
      }

      .static-template-swiper-external-btns-container {
        display: none;
      }

      .static-template-load-more {
        display: block;
        margin: 20px auto 0;
        background: transparent;
        border: 0;
        padding: 10px;
        color: $red;
        font-size: 0.875rem;
        text-decoration: underline;
      }
    }
    @media screen and (min-width: 750px) and (max-width: $break-medium) {
      .video-container {
        p {
          margin-top: 40px;
        }
      }

      .static-template-video-gallery-swiper {
        .swiper-wrapper {
          .swiper-slide {
            margin-top: 30px;
          }
        }
      }

      .static-template-load-more {
        margin-top: 30px;
        font-size: 1rem;
      }
    }
  }

  .static-template-review {
    .review-row {
      @media screen and (min-width: $break-medium) {
        display: flex;
        align-items: center;
      }
    }

    .number-circle {
      text-align: center;
      margin: auto;
      border: 5px solid #bc2224;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      display: flex;
      align-items: center;
      padding: 10px;
      @media screen and (max-width: $break-medium) {
        margin-bottom: 20px;
      }

      .number {
        color: #bc2224;
        font-size: 3.438rem;
        line-height: 2.813rem;
      }

      .text {
        font-size: 1rem;
        line-height: 1.313rem;
        color: #3a3a3a;
        font-weight: 700;
      }
    }

    .review-block {
      background-color: #f2f2f2;
      border-radius: 3px;
      padding: 20px 35px;
      @media screen and (max-width: $break-medium) {
        padding: 20px 15px;
      }

      .swiper-btn {
        background: none;
        border: none;
        z-index: 999;
        width: 30px;
        height: 30px;
        padding: 0;
        outline: none;
        @media screen and (max-width: $break-medium) {
          height: auto;
        }

        &.swiper-container-reviews-btn-prev {
          position: absolute;
          top: 40%;
          left: -15px;
          @media screen and (max-width: $break-medium) {
            bottom: 10px;
            left: 85px;
            top: initial;
            > span {
              font-size: 1rem;
              color: #bc2224;
              text-decoration: underline;
            }
          }
        }

        &.swiper-container-reviews-btn-next {
          position: absolute;
          top: 40%;
          right: -15px;
          @media screen and (max-width: $break-medium) {
            bottom: 10px;
            right: 85px;
            top: initial;
            > span {
              font-size: 1rem;
              color: #bc2224;
              text-decoration: underline;
            }
          }
        }
      }

      .thin-top {
        color: #3a3a3a;
        font-size: 1.313rem;
        line-height: 1.625rem;
        text-align: center;
        margin-bottom: 20px;
        @media screen and (max-width: $break-medium) {
          font-size: 1.125rem;
          line-height: 1.625rem;
          margin-bottom: 20px;
          span {
            color: #bc2224;
            font-size: 1rem;
          }
        }
      }

      .thick-top {
        color: #3a3a3a;
        font-size: 1.313rem;
        line-height: 1.625rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;
        display: none;
        @media screen and (max-width: $break-medium) {
          font-size: 1.125rem;
          line-height: 1.625rem;
          margin-bottom: 5px;
        }
      }

      .review-wrapper {
        position: relative;
        @media screen and (max-width: $break-medium) {
          background-color: #ffffff;
          border-radius: 3px;
        }

        &::after {
          content: '';
          display: block;
          width: 25px;
          height: 25px;
          background: url('https://images.ctfassets.net/zggpk8714k6l/vtdCUhUumijquo8HF7ASj/2ff3df0dae2b484aba2d10577c40d564/facebookIcon.svg');
          position: absolute;
          top: 10px;
          right: 20px;
          z-index: 5;
          @media screen and (max-width: $break-medium) {
            top: 10px;
            right: 10px;
          }
        }

        .swiper-slide {
          background-color: #ffffff;
          padding: 20px;
          @media screen and (max-width: $break-medium) {
            padding: 20px 20px 40px 20px;
            background: none;
          }
        }

        .swiper-pagination {
          background-color: #ffffff;
          width: 100%;
          margin-top: 10px;
          color: #3a3a3a;
          font-weight: 700;
        }

        .slide-content {
          position: relative;
          overflow: auto;
          width: 100%;
          margin: auto;
          padding: 20px;
          background-color: #ffffff;

          .left {
            width: 60px;
            float: left;
            @media screen and (max-width: $break-medium) {
              width: 100%;
              float: none;
              img {
                width: 60px;
                height: 60px;
                margin: auto;
              }
            }

            img {
              border-radius: 50%;
            }
          }

          .right {
            float: left;
            width: calc(100% - 60px);
            padding-left: 20px;
            @media screen and (max-width: $break-medium) {
              width: 100%;
              padding-left: 0;
              float: none;
              text-align: center;
            }

            h3 {
              margin-bottom: 0;
              margin-top: 10px;
              color: #3a3a3a;
              font-size: 1.313rem;
              font-weight: 700;

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .video-text-button-container {
    a {
      color: #000000;
      background: linear-gradient(180deg, #fff349 0, #f3e741);
      box-shadow: 0 3px 15px #74b5664d;
      border: 1px solid #e9db29;
      border-radius: 3px;
      padding: 10px 15px;
      display: block;
      font-weight: 700;
      line-height: 1.375rem;
      text-align: center;
      margin: 15px auto;
      cursor: pointer;
      width: 100%;

      &:hover {
        background: linear-gradient(180deg, #f3e741 0, #fff349);
      }

      @media screen and (min-width: $break-medium) {
        max-width: 200px;
      }
    }
  }

  .static-template-video-text {
    .video-text-inner {
      display: flex;
      align-items: center;
    }

    .static-template-card {
      width: 100%;
    }

    .video-text-image {
      @media screen and (min-width: $break-medium) {
        padding-left: 0;
      }
    }

    .video-text-text {
      @media screen and (min-width: $break-medium) {
        padding-right: 0;
      }
    }
  }

  .static-template-hero-image {
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    .hero-content {
      width: 100%;
      max-width: 730px;
      padding: 20px;
      border: 3px solid #fff;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      h1 {
        margin: 0;
        padding: 0;
        font-family: $fontFamilyHeadings;
        font-size: 45px;
        font-weight: 700;
        color: $yellow;
        text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      }

      p {
        font-size: 1.313rem;
        margin: 15px 0 0;
        color: #fff;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
      }
    }

    .jump-banner {
      position: relative;
      background-size: cover;
      background-position: center top;
      min-height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      h1 {
        z-index: 2;
        color: var(--color-white);
        text-transform: uppercase;
        text-align: center;
        font-size: 10rem;
        font-weight: 700;
        line-height: 10rem;
        width: 75%;
        padding: 20px 0;
      }

      &::before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #1a1818;
        opacity: 0.45;
      }
    }

    @media screen and (max-width: $break-medium) {
      max-height: none;
      overflow: visible;

      .hero-content {
        width: calc(100% - 20px);
        max-width: 650px;
        border-radius: 3px;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: $greyShadow;

        h1 {
          text-shadow: none;
          font-size: 1.375rem;
        }

        p {
          font-size: 1rem;
          color: $mediumGrey;
          text-shadow: none;
        }
      }

      .jump-banner {
        h1 {
          font-size: 3rem;
          line-height: normal;
          width: 85%;
        }
      }
    }

    @media screen and (min-width: $break-xx-large) {
      max-height: 60vh;
    }

    &.full-height {
      max-height: none !important;
    }
  }

  .agent-submission-form {
    .form-group {
      label {
        width: 100%;
        display: block;
      }

      span {
        &.required-error {
          text-indent: 0;
        }
      }

      input,
      select,
      textarea {
        display: block;
        width: 100%;
        border-radius: 0;
      }

      .required-error {
        color: $red;
      }

      &.phone-group {
        margin-bottom: 30px;

        select,
        input,
        .input-group {
          display: inline-block;
          width: 85%;
        }

        input {
          height: 38px;
          line-height: 2.375rem;
          border: 1px solid #707070;
          border-radius: 0 3px 3px 0;
          color: #3a3a3a;
          font-weight: 700;
          font-size: 1rem;

          &#agentNumber:focus {
            outline: 0;
            background: none;
            box-shadow: none;
          }
        }

        select {
          width: 15%;
          margin-right: 0;
          height: 38px;
          border-radius: 3px 0 0 3px;
        }
      }

      &.includingInput {
        position: relative;

        #customAmount {
          position: absolute;
          width: 90%;
          appearance: none;
          border-right: 0;
        }

        #amountToPayWithInput {
          appearance: auto;
          cursor: pointer;
        }
      }
    }

    .btn {
      height: 38px;
      line-height: 1.125rem;
      margin: 0;
      width: 100%;
      display: block;
      background: linear-gradient(180deg, #5cb85c 0, #53aa53);
      border: 1px solid #3c773d;
      font-size: 1.125rem;
      font-weight: 700;

      &:hover {
        background: linear-gradient(180deg, #53aa53 0, #5cb85c);
        color: #ffffff;
      }

      &:disabled {
        background: #ffffff;
        cursor: not-allowed;
        pointer-events: all;
        border: 1px solid $red;
        box-shadow: none;
        color: #000000;
        font-weight: 300;
      }
    }
  }

  .static-jumbotron-wrapper {
    position: relative;

    * {
      border-radius: 0;
    }

    .swiper {
      width: 100%;
      margin: auto;
      @media screen and (min-width: $break-medium) {
        height: 450px;
      }
    }

    .swiper-static-jumbotron-btn-prev,
    .swiper-static-jumbotron-btn-next {
      position: absolute;
      cursor: pointer;
      user-select: none;
      top: 0;
      width: 50px;
      height: 100%;
      background: #00000070;
      z-index: 9;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .swiper-static-jumbotron-btn-prev {
      left: 0;
    }

    .swiper-static-jumbotron-btn-next {
      right: 0;
    }
  }

  .controls-next-prev {
    text-align: center;
    margin-top: 20px;

    .controls-inner {
      user-select: none;
      display: inline;

      > div {
        display: inline;
      }

      .swiper-btn {
        outline: none;
      }
    }

    .swiper-pagination-fraction {
      color: #bc2224;
      width: auto;
    }

    .swiper-pagination-bullet {
      border: 1px solid #bc2224;
      background-color: #ffffff;
      opacity: 1;
      outline: none;
      width: 12px;
      height: 12px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .swiper-pagination-bullet-active {
      background: #bc2224;
    }

    .next,
    .prev {
      background-repeat: no-repeat;
      cursor: pointer;
      background-size: contain;
      background-position: 0 0;

      span {
        font-size: 1rem;
        text-decoration: underline;
        color: #bc2224;
        line-height: 1.5rem;
      }
    }

    .prev {
      background-image: url('https://images.ctfassets.net/zggpk8714k6l/4sexzloTKKOPPg1oAfd7R8/f49cfa6b8aa33964d365811503ee99a4/Arrow-left-red.svg');
      padding-left: 20px;
      margin-right: 20px;
    }

    .next {
      background-image: url('https://images.ctfassets.net/zggpk8714k6l/3wnPfFsoNpTy9L9fKdRyzW/7d415dfaddda78511660add1086fdc51/Arrow-right-red.svg');
      padding-right: 20px;
      background-position: 100% 0;
      margin-left: 20px;
    }
  }

  .jumbotron-pagination-wrapper {
    text-align: center;
    margin-top: 20px;

    .swiper-static-jumbotron-pagination {
      display: inline-block;
    }
  }

  .static-template-jumbotron {
    img {
      cursor: pointer;
    }

    .jumbotron-image-title {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #000000a6;
      color: #ffffff;
      padding: 5px 50px;
      text-align: center;
    }
  }

  .fof {
    .row {
      margin-left: -15px;
      margin-right: -15px;
    }

    font-family: $fontFamilyRegular;

    h2 {
      font-size: 2.2rem;
      line-height: 2.6rem;
      font-weight: 700;
      color: $red;
      text-align: center;
      font-family: $fontFamilyHeadings;
      @media screen and (min-width: $break-large) {
        font-size: 3rem;
        line-height: 3rem;
        text-align: left;
      }
      @media screen and (min-width: $break-large) {
        font-size: 36px;
        text-align: left;
      }
    }

    h3 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.8rem;
      color: #3a3a3a;
      margin-top: 0;
      text-align: center;
      font-family: $fontFamilyRegular;
      @media screen and (min-width: $break-large) {
        font-size: 1.125rem;
        text-align: left;
      }
    }

    h2 {
      @media screen and (max-width: $break-large) {
        margin-top: 20px;
      }
    }

    strong {
      color: $lightGrey;
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-top: 10px;
      display: block;
      @media screen and (max-width: $break-large) {
        text-align: center;
      }
    }

    .content-wrapper-inner {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      max-width: 1000px;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      min-height: 60vh;

      .fof-content-block {
        @media screen and (min-width: $break-large) {
          position: relative;
          top: 50%;
          transform: translateY(30%);
        }

        .links {
          margin-top: 20px;
          @media screen and (max-width: $break-large) {
            text-align: center;
          }

          br {
            display: none;
          }

          a {
            color: $blue;
            display: inline-block;
            margin-bottom: 5px;
            @media screen and (min-width: $break-large) {
              display: block;
            }
            @media screen and (max-width: $break-large) {
              display: inline-block;
              padding: 5px 10px 5px 0;
              position: relative;
              text-align: center;
              &::after {
                content: '|';
                position: absolute;
                right: 0;
              }
              &:last-child {
                &::after {
                  content: '';
                }
              }
            }
            @media screen and (max-width: 332px) {
              font-size: 1rem;
            }
          }
        }

        .buttons {
          a,
          .btn-generic {
            display: block;
            text-align: center;
            font-weight: 700;
            color: #000;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 5px;
            border-radius: 3px;
            background-color: #fcef51;
            border: 1px solid #e9db29;
            @media screen and (min-width: $break-large) {
              padding-top: 7px;
              padding-bottom: 7px;
              margin-bottom: 0;
            }
          }
        }
      }

      .traveller-image {
        width: 100%;
        height: auto;
        margin: auto;
        max-width: 200px;
        display: block;
        @media screen and (min-width: $break-large) {
          max-width: 300px;
        }
      }
    }
  }

  &.terms-conditions-inner {
    overflow: auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #bc2224;

      strong {
        color: #bc2224;
      }
    }

    strong {
      color: #3a3a3a;
    }

    table {
      max-width: 100%;

      tr {
        td,
        th {
          border: 1px solid #bfbfbf;
          padding: 5px 25px;
          text-align: center;
          font-size: 1rem;
          @media screen and (max-width: $break-medium) {
            text-align: left;
            font-size: 0.875rem;
            padding: 5px;
          }
        }

        th {
          font-size: 1.125rem;
          font-weight: 700;
          color: #3a3a3a;
          @media screen and (max-width: $break-medium) {
            font-size: 1rem;
          }
        }

        td {
          strong {
            font-size: 1.063rem;
            @media screen and (max-width: $break-medium) {
              font-size: 1rem;
              vertical-align: top;
              line-height: 19px;
            }
          }
        }
      }
    }

    h1 {
      &::after {
        content: '';
        display: block;
        margin-top: 20px;
        height: 1px;
        width: 100%;
        background: -moz-linear-gradient(
          left,
          rgba(188, 34, 36, 1) 0%,
          rgba(254, 253, 253, 0) 99%,
          rgba(255, 255, 255, 0) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          rgba(188, 34, 36, 1) 0%,
          rgba(254, 253, 253, 0) 99%,
          rgba(255, 255, 255, 0) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          rgba(188, 34, 36, 1) 0%,
          rgba(254, 253, 253, 0) 99%,
          rgba(255, 255, 255, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bc2224', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
      }
    }

    ul {
      list-style: none;
      padding-left: 15px;

      + br {
        display: none;
      }

      li {
        font-size: 1rem;
        @media screen and (max-width: $break-medium) {
          font-size: 0.875rem;
        }

        &::before {
          content: '\2022';
          color: #bc2224;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }

    .tc-section-white {
      background-color: #ffffff;
      border-radius: 3px;
      padding: 20px 30px;
      margin-bottom: 30px;

      h1.with-button {
        .button-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          span {
            width: 50%;
            @media screen and (max-width: $break-medium) {
              width: 75%;
            }
            @media screen and (max-width: $break-medium-5) {
              width: 100%;
            }
          }
        }

        a.btn {
          float: right;
          background: transparent linear-gradient(180deg, #0a85c7 0%, #097bb8 100%) 0 0 no-repeat padding-box;
          box-shadow: 0 3px 10px #0a85c74d;
          border: 1px solid #056598;
          padding: 15px 25px;
          border-radius: 3px;
          font: normal normal bold 16px/7px $fontFamilyRegular;
          letter-spacing: 0;
          color: #ffffff;
          @media screen and (max-width: $break-medium) {
            margin-top: 25px;
          }

          &:hover {
            background: transparent linear-gradient(180deg, #097bb8 0%, #0a85c7 100%) 0 0 no-repeat padding-box;
            box-shadow: 0 3px 10px #0a85c74d;
            border: 1px solid #056598;
          }
        }
      }

      h3 {
        font-size: 1.75rem;
        line-height: 2.125rem;
        margin: 0 0 30px 0;
        @media screen and (max-width: $break-medium) {
          font-size: 1.313rem;
          line-height: 1.625rem;
        }

        &::after {
          content: '';
          display: block;
          margin-top: 10px;
          height: 1px;
          width: 100%;
          background: -moz-linear-gradient(
            left,
            rgba(188, 34, 36, 1) 0%,
            rgba(254, 253, 253, 0) 99%,
            rgba(255, 255, 255, 0) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(188, 34, 36, 1) 0%,
            rgba(254, 253, 253, 0) 99%,
            rgba(255, 255, 255, 0) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(188, 34, 36, 1) 0%,
            rgba(254, 253, 253, 0) 99%,
            rgba(255, 255, 255, 0) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bc2224', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
        }

        + br {
          display: none;
        }
      }

      h4 {
        + br {
          display: none;
        }
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
        @media screen and (max-width: $break-medium) {
          font-size: 0.875rem;
          line-height: 1.313rem;
        }
      }

      .tc-section-white-inner {
        h1 {
          display: none;
        }
      }
    }

    .tc-section-heading {
      font-family: $fontFamilyRegular;
      font-size: 2.125rem;
      line-height: 2.5rem;

      h1 {
        &::after {
          display: none;
        }
      }
    }
  }

  form {
    &.generic-expat-styled {
      margin-top: 30px;
      font-family: $fontFamilyRegular;
      box-sizing: border-box;

      .generic-expat-form-cta {
        background: transparent linear-gradient(180deg, #0a85c7 0%, #097bb8 100%) 0 0 no-repeat padding-box;
        border: 1px solid #056598;
        border-radius: 3px;
        color: #ffffff;
        height: 45px;
        line-height: 2.813em;
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        width: 100%;
        cursor: pointer;
        outline: none;

        &[disabled] {
          border: 1px solid #bc2224;
          background: #f2f2f2;
          cursor: not-allowed;
          color: #000000;
          font-weight: 300;
          box-shadow: none;
        }
      }

      .form-group {
        margin: 0 auto 30px;

        .form-select {
          height: 45px;
          display: block;
          border: 1px solid #707070;
          border-radius: 3px;
        }
      }

      .input-group {
        position: relative;
        margin: 0 0 30px 0;
        width: 100%;

        &.has-feedback {
          label {
            & ~ .form-control-feedback {
              top: 5px;
            }
          }
        }

        &.has-error {
          .message {
            display: block;
            font-size: 0.75rem;
            color: #bc2224;
            position: absolute;
            bottom: -20px;
            left: 15px;
          }
        }

        &.no-m {
          margin: 0;
        }

        input,
        textarea {
          height: 45px;
          line-height: 2.813em;
          display: block;
          border: 1px solid #707070;
          border-radius: 3px;
          width: 100%;
          color: #3a3a3a;
          font-weight: 500;
          font-size: 1rem;

          &:focus {
            outline: none;
          }

          &::-webkit-input-placeholder {
            color: #bfbfbf;
          }
        }

        input {
          text-indent: 20px;
        }

        textarea {
          height: 250px;
          line-height: 1rem;
          padding-top: 20px;
        }

        input:focus ~ label,
        input:not(:placeholder-shown) ~ label,
        textarea:focus ~ label,
        textarea:not(:placeholder-shown) ~ label {
          top: -10px;
          font-size: 0.75rem;
          font-weight: 300;
          background-color: #ffffff;
          padding: 0 5px;
        }

        .message {
          display: none;
        }

        label:not(.top-label) {
          position: absolute;
          pointer-events: none;
          left: 15px;
          top: 14px;
          transition: 0.2s ease all;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #bfbfbf;
          font-weight: 600;
        }

        .top-label {
          font-size: 1rem;
          line-height: 1.25rem;
          color: #3a3a3a;
          font-weight: 600;
          width: 100%;
        }

        &.form-upload-group {
          label {
            position: initial;
            pointer-events: none;
          }

          input {
            text-indent: 0;
            border-radius: 3px;
            cursor: pointer;
            line-height: 41px;
          }
        }
      }

      .password-group {
        input {
          &[type='text'] {
            + .icon {
              background-image: url('https://images.ctfassets.net/zggpk8714k6l/52nVKMLAV3YIohOn4ey8VR/14d7b4f3501a2d82ff0047cc060b3eb0/eyecon-visible.svg');
            }
          }

          + .icon {
            position: absolute;
            top: 15px;
            right: 20px;
            background-image: url('https://images.ctfassets.net/zggpk8714k6l/7p8NmqB5Hn9tCdNIWjWPeD/8444147108eda895f12ba5dea5d6c0e2/eyecon-hidden.svg');
            background-repeat: no-repeat;
            background-size: contain;
            width: 22px;
            height: 18px;
            cursor: pointer;
          }
        }
      }

      .input-group-checkbox {
        margin: 0 0 15px 0;

        .message {
          display: none;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        &.has-error {
          border: 1px solid #bc2224;
          padding: 5px 5px 15px 5px;
          border-radius: 3px;

          .form-control-feedback {
            display: none;
          }

          .message {
            display: block;
            position: absolute;
            bottom: -25px;
            background-color: #ffffff;
            padding: 0 5px;
            color: #bc2224;
            font-weight: 300;
            font-size: 0.75rem;
          }

          .container {
            margin-bottom: 0;
          }
        }

        &.has-success {
          .form-control-feedback {
            display: none;
          }
        }

        .container {
          display: block;
          position: relative;
          padding-left: 33px;
          cursor: pointer;
          font-size: 0.875rem;
          line-height: 1.313rem;
          user-select: none;
          color: #707070;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          input:checked ~ .checkmark {
            background-color: #2196f3;

            &::after {
              display: block;
            }
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #ffffff;
          border: 2px solid #0a85c7;
          border-radius: 3px;

          &::after {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .static-template-faq {
    .mat-expansion-panel-body {
      p {
        margin-bottom: 0;
      }

      td {
        padding: 0 4px;
      }
    }
  }

  &.win-a-free-tour-travel-competition,
  &.win-a-free-tour-travel-competition-demo {
    background: var(--color-white);

    .static-template-text-only {
      .button-green {
        text-transform: uppercase;
      }
    }

    .static-template-image-gallery,
    .static-template-signup-section {
      background: var(--grey-scale-0);

      .button-blue {
        text-transform: uppercase;
      }
    }

    .static-template-single-image.cta {
      @media screen and (max-width: $break-medium) {
        overflow-x: hidden;
      }
    }

    .static-template-tour-carousel {
      background: var(--grey-scale-3);

      .button-yellow {
        text-transform: uppercase;
      }
    }

    > div:last-child {
      margin-bottom: 0;
    }

    .static-template-container {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 30px;
      padding-bottom: 45px;
    }

    .static-template-hero-image {
      @media screen and (min-width: $break-xx-large) {
        max-height: 70vh;
      }
    }

    .static-template-image-gallery {
      margin-bottom: 100px;

      h2 {
        font-family: var(--font-family-headings), sans-serif;
        color: var(--color-white);
        text-align: center;
        font-size: 3rem;
        font-style: normal;
        font-weight: 900;
        line-height: normal;

        &::after {
          content: none;
        }
      }

      .static-template-card {
        padding: 15px;
        box-shadow: none;
        background: transparent;
        border: 0;
      }

      .grid-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: -150px;

        .three-col-gal-item {
          position: relative;
          background-size: cover;
          background-position: center;
          border-radius: 0 20px 0 0;
          flex: 1 1 calc(33% - 1rem);
          transition: transform 0.3s;
          min-height: 550px;

          &:hover {
            transform: translateY(+10px);
          }

          .top-badge {
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--yellow-prime);
            color: var(--color-black);
            padding: 0.5rem 1rem;
            font-weight: bold;
            border-radius: 0 0 20px 0;
          }

          .gal-content {
            color: var(--color-white);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            padding: 15px 20px;

            strong {
              display: block;
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 900;
              line-height: 140%;
            }

            p {
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgb(0 0 0 / 0%) 70%);
          }
        }

        @media screen and (max-width: $break-medium) {
          flex-direction: column;
        }
      }

      &.full-main-heading {
        .container-fluid {
          background-size: cover;
          background-position: center;
        }

        .header-wrapper {
          display: flex;
          align-items: center;

          @media screen and (max-width: $break-medium) {
            margin: 10px auto 35px;
            text-align: center;
          }
        }

        .static-template-card {
          display: flex;

          h1 {
            color: var(--color-white);
            text-align: center;
            font-size: 8rem;
            font-style: normal;
            font-weight: 900;
            line-height: 3.5rem;
            margin-bottom: 0;

            span {
              font-size: 4rem;
              font-style: normal;
              font-weight: 900;
              text-transform: uppercase;
            }

            &:after {
              content: none;
            }
          }

          .grid-container {
            width: 100%;

            @media screen and (max-width: $break-medium) {
              margin: 0 auto -100px;
              flex-direction: row;

              .three-col-gal-item {
                min-height: 160px;
              }
            }
          }

          .three-col-gal-item {
            border-radius: 0 !important;

            .top-badge,
            .gal-content {
              display: none;
            }

            &:before {
              content: none;
            }
          }

          .three-col-gal-item:nth-child(1) {
            flex: 1 1 calc(25% - 1rem);
            @media screen and (max-width: $break-medium) {
              flex: 1;
            }
          }

          .three-col-gal-item:nth-child(2) {
            flex: 1 1 calc(35% - 1rem);
            @media screen and (max-width: $break-medium) {
              flex: 1;
            }
          }

          .three-col-gal-item:nth-child(3) {
            flex: 1 1 calc(40% - 1rem);
            @media screen and (max-width: $break-medium) {
              flex: 1;
            }
          }

          @media screen and (max-width: $break-medium) {
            flex-direction: column;
            margin: 45px auto;
            box-shadow: none;
            padding: 0;
          }
        }
      }

      @media screen and (max-width: $break-medium) {
        margin-bottom: 155px;
      }
    }

    .static-icon-card {
      h2 {
        font-family: var(--font-family-headings), sans-serif;
        color: var(--grey-scale-0);
        text-align: center;
        font-size: 3rem;
        font-style: normal;
        font-weight: 900;
        line-height: normal;

        &:after {
          content: none;
        }

        @media screen and (max-width: $break-medium) {
          font-size: 2.5rem;
          line-height: normal;
        }
      }

      .icon-card-grid {
        display: flex;
        flex-wrap: nowrap;

        .static-template-card-sml {
          position: relative;
          height: unset !important;
          margin: 10px;
          width: 33%;

          .arrow-wrapper {
            position: absolute;
            top: 50%;
            right: 7px;
            transform: translate(100%, -50%);
            z-index: 5;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 20px;
              height: 110%;
              transform: translate(-50%, -50%);
              background: #f7f7f7;
              z-index: 4;
            }

            svg.arrow-outer {
              position: relative;
              fill: #f7f7f7;
            }

            svg.arrow-inner {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              height: 25px;
              width: 50px;
              z-index: 5;
            }
          }
        }

        @media screen and (max-width: $break-medium) {
          flex-direction: column;
          align-items: center;

          .static-template-card-sml {
            width: 100%;

            .arrow-wrapper {
              top: unset;
              bottom: 7px;
              right: 50%;
              transform: rotate(90deg) translate(100%, -50%);
            }
          }
        }
      }

      .static-template-card-sml {
        border-radius: 20px;
        background: var(--color-white);
        border: 3px solid var(--red-prime);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

        .static-template-card-sml-text {
          background: transparent !important;

          h3 {
            color: var(--red-prime);
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 800;
            line-height: 140%;
          }

          .card-sml-sub,
          .card-sml-content {
            display: block;

            p {
              color: var(--grey-scale-0);
              text-align: center;
              font-size: 1.5rem;
              font-style: normal;
              line-height: 140%;
              margin-bottom: 0;
            }
          }

          .card-sml-sub,
          strong {
            font-weight: 700;
          }

          .card-sml-content {
            font-weight: 400;
          }
        }

        @media screen and (max-width: $break-medium) {
          margin-bottom: 35px;
          height: auto;
        }
      }
    }

    .static-template-signup-section {
      text-align: center;

      h2 {
        color: var(--color-white);
        font-size: 3rem;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-bottom: 0;
      }

      .sub-heading {
        p {
          color: var(--yellow-prime);
          font-size: 2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      @media screen and (max-width: $break-medium) {
        h2 {
          font-size: 2.5rem;
          line-height: normal;
        }
        .sub-heading {
          p {
            font-size: 2rem;
            line-height: normal;
          }
        }
      }
    }
  }

  &.jump-air-form {
    .static-template-single-image.cta {
      background: var(--grey-scale-0);
      margin-bottom: 0;

      .static-template-container {
        padding-top: 0;
        padding-bottom: 0;
        max-width: 100% !important;
        margin-bottom: 0;
      }

      .cta-content {
        padding: 55px 25px;
        width: 85%;
        float: right;

        h2,
        h3 {
          color: var(--color-white);
          font-size: 3rem;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }

        h3 p {
          color: var(--yellow-prime);
          font-size: 3rem;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }

        .cta-content-text p {
          color: var(--color-white);
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        .button p {
          margin: 0;
        }

        @media screen and (max-width: $break-large) {
          width: 100%;
          float: none;
          text-align: center;
        }
      }

      .bg-image-col {
        min-height: 250px;
        background-size: cover;
        background-position: center;
      }

      .button-blue {
        text-transform: uppercase;
      }

      @media screen and (max-width: $break-medium) {
        overflow-x: hidden;
      }
    }

    .airtable-embed {
      transition: height 0.2s ease;
      height: 1000px;
      margin-bottom: 35px;

      @media screen and (max-width: $break-small) {
        height: 1066px;
      }
      @media screen and (max-width: 510px) {
        height: 1175px;
      }
      @media screen and (max-width: $break-xsmall) {
        height: 1250px;
      }
      @media screen and (max-width: 355px) {
        height: 1300px;
      }
      @media screen and (max-width: 355px) {
        height: 1400px;
      }
      @media screen and (max-width: 275px) {
        height: 1450px;
      }
    }
  }

  .bg-white {
    background: var(--color-white);
  }

  .c-yellow-prime {
    color: var(--yellow-prime);
  }

  .c-red-prime {
    color: var(--red-prime);
  }

  .section-background {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.calendly-contact-swal-container {
  .calendly-contact-swal-outer-wrapper {
    @media screen and (min-width: $break-medium) {
      width: 900px;
    }

    .calendly-col-inner {
      height: 130px;
      margin-top: 30px;
      position: relative;
    }

    hr {
      margin: 40px 0 0 0;
      border-top: 2px solid #8c8b8b;
    }

    .calendly-separator-col {
      &::before {
        @media screen and (min-width: $break-medium) {
          content: '';
          position: absolute;
          right: -13px;
          height: 150px;
          width: 2px;
          background-color: #e5e5e5;
        }
      }
    }
  }

  a {
    &.btn-calendly {
      background: #ffffff;
      border: 1px solid #0a85c7;
      border-radius: 3px;
      opacity: 1;
      font-weight: 700;
      font-family: $fontFamilyRegular;
      width: 300px;
      padding: 10px 10px;

      &:hover {
        color: #ffffff;
        background: #0a85c7;
      }

      @media screen and (max-width: $break-medium) {
        width: 100%;
      }
    }
  }
}
