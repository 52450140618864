@import '/src/shared-styles/global-variables';

.main-menu {
  .logo {
    //transition: all 0.4s ease-in-out; //adding this breaks the tour page sub menu
    max-height: 60px;
    @media screen and (max-width: 992px) {
      max-height: 35px;
    }
  }

  .white-right-side {
    z-index: 10;
  }

  .nav-white-bar {
    background-color: #ffffff;
    padding: 10px 0;
    @media screen and (min-width: 992px) {
      min-height: 80px;
    }
  }

  .nav-red-bar {
    background-color: $red;
    color: #ffffff;

    a {
      color: #ffffff;
    }

    > .container {
      > .row {
        > div {
          > ul {
            // First ul in red bar
            display: block;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 55px;

            &.red-bar-list-container {
              white-space: nowrap;
            }

            .new-menu-item {
              font-size: 0.688rem;
              color: yellow;
              margin-top: -5px;
              position: absolute;
              margin-left: 3px;
              font-style: italic;
            }

            > li {
              /*White items in red bar*/
              margin: 0;
              display: inline-block;
              list-style-type: none;
              line-height: 55px;

              &:first-child {
                margin-left: 15px;
              }

              &:hover {
                > div {
                  &::after {
                    content: '';
                    transition: scale 1s ease-in-out;
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #faee53;
                    position: relative;
                    left: 50%;
                    margin-bottom: 53px;
                    bottom: 10px;
                  }
                }

                > a {
                  color: $yellow;
                }
              }

              > a {
                height: 45px;
                line-height: 2.813rem;
              }

              .has-sub-menu {
                height: 55px;
                line-height: 55px;

                .dropout {
                  transition: all 0.3s ease-in;
                  position: absolute;
                  top: -$menuDropoutHeight;
                  color: #000000;
                  width: 90%;
                  left: 0;
                  margin: 0 auto;
                  right: 0;
                  z-index: -1;
                  white-space: normal;

                  .container {
                    padding: 0;
                  }
                }
              }

              > a,
              > span,
              > div {
                cursor: pointer;
                color: #ffffff;
                text-decoration: none;
                font-size: 0.875rem;
                display: block;
                padding: 0 15px;
              }

              > ul {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .triangle {
    position: absolute;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #fafafa;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: -15px;
      right: 5px;
      margin-left: 0;
      box-sizing: border-box;
      border: 10px solid #ffffff;
      transform-origin: 0 0;
      transform: rotate(45deg);
    }

    &::after {
      z-index: -10;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
    }

    &::before {
      z-index: 10;
    }
  }

  .logo-slogan {
    color: $red;
    line-height: 1.125rem;
    margin-left: 15px;
    font-weight: 700;
    display: inline-block;
  }

  .social-slogan {
    margin-right: 15px;

    .booking-link {
      position: relative;
      color: var(--booking-com-blue);
      font-weight: 500;
      margin-right: 15px;

      &:hover {
        opacity: 1;
      }
    }

    .social-link {
      height: 30px;
      background-color: #ffffff;
      margin: 0 5px;

      span {
        color: #707070;
        background: #f7f7f7;
      }
    }
  }

  .hover-label {
    display: flex;
    justify-content: flex-end;

    img {
      z-index: 2;
      background: #fff;

      .full-logo {
        padding: 5px 15px !important;
      }
    }

    span {
      display: inline;
      width: 30px;
      height: 30px;
      padding: 5px 0;
      margin-right: -15px;
      border-radius: 23px 0 0 23px;
      background: #edf1f8;
      color: var(--booking-com-blue);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      opacity: 0;
      overflow: hidden;
      z-index: 1;

      transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
      transform: translateX(50%);

      @media screen and (max-width: $break-medium-5) {
        display: none;
      }
    }

    &:hover {
      opacity: 1;

      span {
        opacity: 1;
        transform: translateX(0%);
        width: 100%;
        padding: 5px 20px;
      }
    }

    &.full-button {
      img {
        z-index: 2;
        border-radius: 23px;
        border: 1px solid var(--booking-com-blue);
        padding: 5px 15px;
      }

      .ball-logo {
        display: none;
      }
    }

    &.ball-button {
      img {
        border-radius: 50%;
      }

      .full-logo {
        display: none;
      }
    }
  }

  .contact-help {
    .dropdown-box {
      span {
        font-size: 1rem;
        color: #707070;

        &:hover {
          color: $red;
          opacity: 0.8;
        }
      }

      .triangle {
        width: 250px;
        left: -200px;
        top: 20px;
        border-radius: 3px;
      }

      .user-not-logged {
        .blue-cta {
          width: 100%;
        }

        .register-trigger {
          text-align: center;
          color: $red;
          padding: 10px 0 0;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .user-logged {
        .blue-cta {
          width: 100%;
        }

        .logout {
          text-align: center;
          color: $red;
          padding: 10px 0 0;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .account-divider {
      @media screen and (min-width: $break-medium) {
        margin-right: 0;
      }
    }

    .phone {
      color: $red;
      font-weight: 700;
      padding-left: 20px;
      font-size: 1rem;
      text-decoration: none;
      white-space: nowrap;
      background-image: url('https://images.ctfassets.net/zggpk8714k6l/4PbBqIZoDAdOP2mch3b7Nb/03359ecc47aec386e824037710931933/Phone.svg?h=16');
      background-size: contain;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  }

  .menu-display-panel {
    .inner-panel {
      background: #ffffff;
      box-shadow: 0 1px 4px 0 rgb(0 0 0 / 24%);
      border-radius: 0 0 3px 3px;
      height: 70vh;
      line-height: 1.313rem;
      font-size: 0.875rem;
      border-top: 4px solid $yellow;

      .inner-panel-height-container {
        height: 100%;
        overflow: hidden;

        > .row {
          height: 100%;
        }
      }

      .nav-sub-section-row,
      .nav-sub-section-left,
      .nav-sub-section-right {
        max-height: 75vh;
      }

      .nav-sub-section-left {
        color: #3a3a3a;

        div {
          height: 45px;
          line-height: 2.813rem;
          padding: 0 20px;
        }

        .activeSubsection {
          background-color: $lightGrey;
        }
      }

      .nav-sub-section-right {
        background-color: $lightGrey;
        padding: 10px;

        .nav-sub-section-right-inner {
          &.padding-inner {
            padding: 15px 15px;
          }

          .active-sub-section-swiper-controls {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            text-align: center;

            .inner-wrap {
              overflow: auto;
              text-align: center;
              display: inline-block;

              > button,
              > div {
                float: left;
                line-height: 2rem;
              }

              .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                border: 1px solid $red;
                margin: 0 5px;
                background: 0;
                opacity: 1;

                &:last-child {
                  margin-right: 0;
                }
              }

              .swiper-pagination-bullet-active {
                background: $red;
              }
            }

            .swiper-custom-prev,
            .swiper-custom-pagination {
              margin-right: 5px;
            }

            .swiper-custom-prev,
            .swiper-custom-next {
              background-size: contain;
              background-repeat: no-repeat;
              background-position: 0 50%;
              border: none;
              width: 20px;
              height: 32px;
            }

            .swiper-custom-prev {
              background-image: url('https://images.ctfassets.net/zggpk8714k6l/4u8mAn5ylgrwTvy7AMn3U8/d5f1678cf6d555c859dbe07324e53bea/Chevron_left.svg?h=15');
            }

            .swiper-custom-next {
              background-image: url('https://images.ctfassets.net/zggpk8714k6l/4cA3y4gnLA87dnQqu8YCuL/e2b2367bf58f0fbc3b55c4cc63c59b88/Chevron_right.svg?h=15');
              background-position: 100% 50%;
            }
          }

          .nav-sub-section-tour-card {
            img {
              border-radius: 3px 3px 0 0;
              width: 100%;
            }

            .title {
              width: 100%;
              height: 45px;
              line-height: 2.813em;
              text-align: center;
              background: #fff;
              font-weight: 700;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              display: block;
              color: #3a3a3a;
              text-decoration: none;
              font-size: 0.75rem;

              @media screen and (max-height: 620px) and (min-width: 1366px) {
                height: 30px;
                line-height: 1.875rem;
              }

              @media screen and (min-width: 1439px) and (max-height: 900px) {
                height: 35px;
                line-height: 2.188rem;
              }
            }
          }
        }
      }

      .nav-sub-suction-heading {
        padding: 20px;
        @media screen and (min-width: 1366px) and (max-width: 1367px) {
          padding: 10px;
        }
      }

      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      h5 {
        font-size: 1rem;
        color: #3a3a3a;
        margin: 0;
        padding: 0;
        line-height: 1.75rem;
        font-weight: 700;
        font-family: $fontFamilyRegular;
      }
    }
  }

  $search-height: 35px;

  .nav-search {
    form {
      width: 100%;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      height: $search-height;
      border: 0;
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;
      height: $search-height;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .mat-mdc-form-field-infix {
      display: flex;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      width: 100%;
      height: $search-height;
    }

    .styled-input-group {
      .form-control {
        padding: 5px 15px;
        color: #212529;
        background-color: #fff;
        border: 0 !important;
        height: $search-height !important;
        border-radius: 3px 0 0 3px;

        &:focus {
          background-color: #ffffff;
          border: #ffffff;
          outline: 0;
          box-shadow: none;
        }
      }

      .styled-input-group-btn {
        padding: 0;
        background: $yellow;
        border: 1px solid $yellowBorder;
        cursor: pointer;
        height: $search-height;
        min-width: 40px;

        img {
          width: 20px;
        }
      }
    }

    //
    outline: 0;
    appearance: none;

    .input-group-text {
      padding: 5px;
      background-color: $yellow;
      border: 1px solid $yellowBorder;
      cursor: pointer;
    }

    .form-control {
      padding: 5px 10px;
      color: $text;
      border: #ffffff;

      &:focus {
        background-color: #ffffff;
        border: #ffffff;
        outline: 0;
        box-shadow: none;
      }
    }

    img {
      width: 70%;
      height: auto;
      margin: 0 auto;
    }
  }

  .nav-notice {
    color: $blueException;
    background-color: $lightBlue;
    padding: 10px 0;
    border-top: 1px solid $blueException;
    border-bottom: 1px solid $blueException;
    position: absolute;
    width: 100%;
    z-index: -2;

    &.tour-page {
      top: 193px;
      @media screen and (max-width: $break-medium) {
        top: 125px;
      }
    }

    a {
      margin-left: 5px;
      font-weight: 700;
      color: $blueException;
    }

    .notice-wrapper {
      position: relative;
      font-size: 0.875rem;
      @media screen and (min-width: $break-medium) {
        align-items: center;
        justify-content: center;
      }

      .notice-inner {
        @media screen and (max-width: $break-medium) {
          width: 85%;
        }
      }

      @media screen and (max-width: $break-medium) {
        padding-top: 5px;
      }
    }

    .close-notice {
      position: absolute;
      right: 15px;
      cursor: pointer;

      span {
        &:first-child {
          text-decoration: underline;
          margin-right: 10px;
          font-size: 0.625rem;
        }
      }
    }
  }

  .inner-panel-content-wrapper {
    padding: 0 20px 20px 20px;

    a {
      color: $red;
      text-decoration: none;
      margin-bottom: 30px;
      display: block;
    }

    .nav-subsection-about-us {
      .nav-subsection-about-us-heading {
        > * {
          display: inline-block;
          line-height: 1.75rem;
        }

        img {
          height: 20px;
          width: 20px;
          margin: 0 10px 3px 0;
        }

        @media screen and (min-width: $break-medium) {
          h3 {
            font-size: 1rem;
            color: #3a3a3a;
            margin: 0;
            padding: 0;
            line-height: 1.75rem;
            font-weight: 700;
            font-family: $fontFamilyRegular;
          }
        }
      }

      .about-us-content-block {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .nav-inspiration-block {
      transition: box-shadow 0.2s ease-out;
      box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      margin-bottom: 30px;
      overflow: hidden;

      &:hover {
        img {
          transform: scale(1.2);
        }
      }

      img {
        transition: 0.4s ease-in-out;
      }

      span {
        display: block;
        color: $darkGrey;
        padding: 20px 15px;
        font-weight: 700;
      }

      a {
        margin-bottom: 0;
        overflow: hidden;
      }
    }
  }

  .controls-next-prev {
    margin: 10px auto;

    .prev {
      background-position: 0 2px;
    }

    .next {
      background-position: 100% 2px;
    }
  }

  .mobile-icon {
    height: 25px;
  }

  .header-mobile-menu-container-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  .header-mobile-menu-container-outer {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #00000091;
    z-index: 11;

    .header-mobile-menu-container-inner {
      height: 100vh;
      background-color: #ffffff;
      width: 80vw;
      padding: 50px 15px 15px 15px;
      position: absolute;
      top: 0;
      right: 0;
      overflow-y: scroll;

      h3 {
        height: 50px;
        line-height: 3.125rem;
        color: #bb2326;
        margin: 0;
        padding: 0;
        font-size: 0.875rem;
        font-weight: 700;
        font-family: $fontFamilyRegular;
        position: absolute;
        top: 0;
        left: 15px;
        width: 70%;

        &::after {
          height: 1px;
        }
      }

      .mobile-menu {
        position: absolute;
        top: 0;
        height: 50px;
        width: 50px;

        &.mobile-menu-back-icon {
          left: 0;
        }

        &.mobile-menu-close-icon {
          right: 0;
        }
      }

      .mobile-menu-main-list {
        padding: 0;
        margin: 0;
        height: calc(100vh - 50px);

        > li {
          list-style-type: none;
          padding: 0;
          margin: 0;
          height: 75px;
          line-height: 75px;
          position: relative;

          .new-menu-item {
            position: relative;
            padding-right: 20px;

            &::after {
              content: 'new';
              position: absolute;
              margin-top: -6px;
              right: 0;
              color: #bc2224;
              font-style: italic;
              padding-left: 3px;
              font-size: 0.625rem;
            }
          }

          a {
            cursor: pointer;
            color: #3a3a3a;
            font-size: 1rem;
            width: 100%;
            height: 100%;
            display: block;
            opacity: 1 !important;

            &.mobile-menu-main-sub {
              &::after {
                content: '';
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid #bfbfbf;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }
          }

          img {
            margin-right: 20px;
            margin-top: -3px;
            max-width: 30px;
          }

          &::after {
            content: '';
            display: block;
            background: linear-gradient(90deg, #e8e8e8 0, hsla(0, 0%, 91%, 0));
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          .contact-us-container {
            h1 {
              color: #bb2326;
              height: 50px;
              line-height: 3.125rem;
              margin: 0;
              padding: 0;
              font-size: 1.125rem;
              font-weight: 700;
            }

            a {
              color: #bb2326;
              font-size: 0.875rem;
              font-weight: 400;
              height: 45px;
              line-height: 2.813em;
              opacity: 1 !important;

              img {
                max-height: 20px;
                margin-right: 10px;
                opacity: 1 !important;
              }
            }

            .gradientBorder {
              width: 100%;
              height: 1px;
              background: linear-gradient(90deg, #bc2224, rgba(188, 34, 36, 0));
              margin-bottom: 10px;
              margin-top: 10px;
            }

            .socialIcons {
              display: flex;
              line-height: 0;

              a {
                height: unset;
              }

              .social-bar {
                display: flex;
              }
            }
          }
        }
      }

      .mobile-menu-inner-list {
        display: none;

        width: 100%;
        height: calc(100vh - 50px);
        overflow-y: auto;

        .mobile-sub-nav {
          transition: none;
          display: block;
          position: relative;
          width: 100%;
          color: #3a3a3a;
          padding: 25px 0;
          padding-left: 15px;
          text-decoration: none;
          opacity: 1 !important;

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            background: linear-gradient(90deg, #e8e8e8 0, hsla(0, 0%, 91%, 0));
          }

          &:after {
            transition: transform 0.5s ease;
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #bfbfbf;
            top: 50%;
            right: 15px;
            transform: translateY(-50%) rotate(0deg);
          }

          &.active {
            &:after {
              transform: translateY(-50%) rotate(90deg);
            }
          }

          &.no-caret {
            &:after {
              content: none;
            }
          }
        }

        .mobile-sub-items {
          background: #f2f2f2;
          padding: 15px 0;

          a {
            cursor: pointer;
            transition: none;
            display: block;
            position: relative;
            width: 100%;
            color: #3a3a3a;
            padding: 15px;
            padding-left: 30px;
            font-size: 0.75rem;

            span {
              text-decoration: underline;
            }

            small {
              color: #bb2326;
              margin-left: 10px;
            }
          }

          &.about {
            padding: 15px;

            a {
              transition: none;
              width: auto;
              padding: 0;
              display: inline-block;
              color: #bb2326;
            }
          }
        }
      }

      &.inSub {
        padding: 50px 0 15px 15px;

        h3.red-fade {
          width: 60%;
          left: 65px;
        }

        .mobile-menu-main-list {
          width: 50px;
          height: calc(100vh - 50px);
          background: #bfbfbf;
          position: absolute;
          top: 50px;
          left: 0;

          a {
            text-align: center;

            img {
              margin-left: auto;
              margin-right: auto;
            }

            span {
              display: none;
            }

            &.mobile-menu-main-sub {
              &::after {
                content: none;
              }
            }

            &.active {
              background: #fff;
            }
          }
        }

        .mobile-menu-inner-list {
          display: inline-block;
        }
      }
    }
  }

  .divider-item {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -16px;
      width: 1px;
      height: 20px;
      background-color: #eee;
      transform: translateY(-50%);
    }

    &.mobile-nav-trigger {
      margin-right: 0;
    }
  }
}
