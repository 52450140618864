@import 'src/shared-styles/global-variables';

.search-form {
  width: 100% !important;
}

:host {
  #cdk-overlay-0 {
    width: 100% !important;

    //&.cdk-overlay-plane {
    //  min-width: calc(min(100vw - 10px, 600px));
    //}
  }
}
