$red: #bc2224;
$yellow: #fcef51;
$darkYellow: #efe24c;
$yellowBorder: #e9db29;
$yellowGradient: linear-gradient(180deg, $yellow 0, $darkYellow);
$yellowGradientInverse: linear-gradient(180deg, $darkYellow 0, $yellow);
$text: #707070;
$blue: #0a85c7;
$blueException: #056598;
$lightBlue: #cee7f4;
$menuDropoutHeight: 70vh;
$lightGrey: #f2f2f2;
$darkGrey: #3a3a3a;
$mediumGrey: #707070;
$greyShadow: 0 3px 10px rgba(0, 0, 0, 0.1);

$fontFamilyRegular: 'Barlow', Arial, Helvetica, sans-serif;
$fontFamilyHeadings: 'Barlow', Arial, Helvetica, sans-serif;
$swiperWrapperHeight: 63vh;
$break-xx-large: 1400px;
$break-x-large: 1200px;
$break-large: 1024px;
$break-medium: 994px;
$break-medium-5: 768px;
$break-small: 640px;
$break-xsmall: 420px;

/*Checkout start*/
$border-seperator: 2px solid #ffffff;
$heading-color: #435c71;
/*Checkout end*/

@mixin swiper-button-disabled {
  filter: grayscale(1);

  &:before {
    cursor: not-allowed;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}
