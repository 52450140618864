@import './src/shared-styles/global-variables';

.footer {
  background-color: $red;
  color: #ffffff;

  a {
    cursor: pointer;
    text-decoration: none;
  }
  a,
  p {
    color: #ffffff;
    font-family: $fontFamilyRegular;
    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (max-width: 994px) {
      font-size: 0.875rem;
      line-height: 1.313rem;
    }
  }

  h3 {
    color: $yellow;
    font-size: 1.5rem;
    line-height: 1.813rem;
    margin: 0 0 20px;
    font-family: $fontFamilyRegular;
    font-weight: 300;

    @media screen and (max-width: 994px) {
      font-size: 1.125rem;
      line-height: 1.625rem;
      font-weight: 300;
    }
  }

  .footer-block {
    position: relative;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;

    &:first-child {
      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      background-image: linear-gradient(#bc2224, #9a0d11, #bc2224);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
    }

    @media screen and (max-width: 994px) {
      text-align: center;
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-bottom: 10px;
      padding-bottom: 10px;

      p {
        margin-bottom: 10px;
      }
      &::after {
        height: 1px;
        width: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #9a0d11 50%, rgba(255, 255, 255, 0));
      }
    }
  }

  .footer-contact {
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        padding-left: 30px;
        padding-bottom: 10px;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: 0 4px;

        &.tel {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/7fEe0MqxG16ZPUGaCtzgBX/4d723a21bda27ed4942519b9b183645c/footerPhone.svg');
          padding-top: 3px;
        }
        &.times {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/6jpzLnWFJy0uDEW0YscBhJ/a8a869d747287fccd26f60912fc97c99/footerClock.svg');
          max-width: 185px;
          padding-top: 4px;
        }
        &.email {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/6WsKQOnyrtp5Fo05y9lmdJ/8cde16620c9c669bcc2fe105c7056242/footerEmail.svg');
          background-position: 0 5px;
        }
        &.footerFacebook {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/1pJeKycgBvEJIt5cbztl2o/05d0c1df1d001e25c4a7484467c86f94/footerMessenger.svg');
          background-position: 0 2px;
        }
        &.footerWhatsapp {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/4oq7mfijPE3NjRDATEYqqa/55ab41be256e3594adc29714d3e27818/whatsapp.svg');
          background-position: 0 1px;
        }
        &.footerChat {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/7r5rqSuDzlmxrZK7FjEUz4/c610ddac2b65446db2d84385034f8ff8/chat.svg');
          background-position: 0 2px;
        }
      }
      @media screen and (max-width: 994px) {
        display: inline-block;
        text-align: left;
      }
    }
  }

  .footer-social {
    margin: 30px 0;
    text-align: center;

    .footer-social-inner {
      display: inline-block;

      a {
        background-repeat: no-repeat;
        background-size: 35px;
        background-position: 0 0;
        display: inline-block;
        width: 35px;
        height: 35px;
        margin: 0 15px;

        &.twitter {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/63KnBwfpCfwTnhMKlMVc3F/c88ac2652dde23af2a4d8b29cb17dc0b/Twitter.svg');
        }

        &.facebook {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/3jAMh3qzuu35jPi9jbIt2w/12501032021a533b940280e0fa0554ec/Facebook.svg');
        }

        &.instagram {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/t6eTiFGlXfSK8TKQrpdJ9/a67de67c7564fd7a1ee6c0b3a1ac62b2/Instagram.svg');
        }

        &.youtube {
          background-image: url('https://images.ctfassets.net/zggpk8714k6l/5DYTJlI3uIjin8YFJL6sq8/88ab9a8b335eb53c24812dba356172d7/Youtube.svg');
        }
      }
    }
  }

  .footer-security-bar {
    background-color: #ffffff;
    color: #707070;
    font-size: 1rem;
    padding: 30px 0;

    .row {
      > div {
        @media screen and (max-width: $break-medium) {
          margin-bottom: 10px;
        }
      }
    }

    .d-flex {
      justify-content: center;
    }

    a {
      color: #707070;
    }

    .encrypted {
      p {
        display: inline-block;
        padding-left: 30px;
        background-image: url('https://images.ctfassets.net/zggpk8714k6l/3bp1k6DWWVPGaqsO6e2Kuh/a4febdc64fac34572feac38377262758/lock.svg');
        background-repeat: no-repeat;
        background-size: 16px 21px;
        background-position: 0 3px;
        line-height: 1.813rem;
        color: #bc2224;
        font-weight: 700;
        font-size: 1rem;
        margin: 0 0 0 20px;
      }
    }

    .payment-cards {
      img {
        margin-left: 2%;
        height: 30px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .copyright-inner {
      width: 100%;
      justify-content: space-around;

      &.isTourPage {
        @media screen and (max-width: $break-medium) {
          margin-bottom: 80px;
        }
      }
    }
  }
}
