@import '/src/shared-styles/global-variables';

.app-section {
  .app-header {
    position: sticky;
    z-index: 10;
    top: 0;
    width: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }
  opacity: 0;
  &.no-loader {
    opacity: 1 !important;
  }
}

.loaderWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f1f1f1;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  &.opac {
    background: rgb(241 241 241 / 50%);
  }

  img {
    width: 35%;
    max-width: 350px;

    @media screen and (max-width: $break-medium) {
      width: 65%;
    }
  }
}
