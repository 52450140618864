@import './src/shared-styles/global-variables';

.whatsapp {
  font-family: $fontFamilyRegular;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  span {
    color: #25d366;
    &:hover {
      color: #25d366;
    }
  }
}
